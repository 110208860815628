.trackBrowser {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 15px;
  width: 100%;
}

.trackPlates {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: space-around;
}

.trackListing {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.trackInfo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  flex: 6 1 auto;
  margin-left: 2%;
}

.trackArtistAndName {
  display: flex;
  flex-flow: row wrap;
  flex: 2 auto;
}

.trackPlayCount {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 auto;
}

ul {
  margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-flow: column nowrap;
}

li {
  list-style-type: none;
  font-size: 1rem;
  padding: 15px;
  margin-bottom: 15px;
  background: #f1e3ff;
}

.artwork {
  margin-left: 5%;
  display: block;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.hoverContainer {
  position: relative;
  text-align: center;
  transform: translate(35%, -280%);
  transition: 0.5s ease;
  opacity: 0;
}

.hoverPlay {
  background-color: #4C2F50;
  color: white;
  font-size: 16px;
  padding: 8px 8px;
  width: 20%;
}

.artwork:hover img {
  opacity: 0.4;
}

.artwork:hover .hoverContainer {
  opacity: 1;
}
