/*.artwork img {
  overflow: hidden;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  float: left;
}

.trackListing {
  height: 50px;
  line-height: 20px;
  vertical-align: middle;
  font-size: 12px;
  text-align: left;
  margin: auto;
}

.title {
  font-size: 28px;
  font-weight: bold;
}

.artist {
  font-size: 20px;
}

.host {
  font-size: 8px;
}*/

.menuArea {
  height: 100%;
  overflow: hidden;
}

#MenuAreaButtons {
  max-height: 0px;
  overflow: hidden;
  transition: 1.2s all cubic-bezier(0.68, -0.27, 0.63, 1.39);
}

#MenuAreaButtons.Visible {
  max-height: 800px;
}
