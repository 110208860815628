.playingBar {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
  top: 0;
  width: 100%;
  margin-top: 0;
  background: white;
  padding: 10px;
  height: 60px;
  box-shadow: 0px 0.5px 6px black;
	z-index: 1;
}

.nowPlaying {
	display: flex;
	height: 30px;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.nowPlaying img {
  overflow: hidden;
  border-radius: 5px;
  height: 25px;
  width: 25px;
}

.playingControlsBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 30px;
	width: 100%;
}

.volumeTitle {
  font-size: 8px;
	justify-content: center;
}

.volumeScrubber {
	flex: 2 auto;
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.durationScrubber {
	flex: 5 auto;
}

.playPauseButton {
	flex: 1 100px;
}
